@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: "Nunito", sans-serif;
}

.custom {
	animation: scroll 40s linear infinite ;
	scroll-behavior: smooth;
}

.custom1 {
	animation: scroll 25s linear infinite ;
	scroll-behavior: smooth;
}

.custom2 {
	animation: scroll 30s linear infinite ;
	scroll-behavior: smooth;
}

.footer-section {
	/* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(65,247,203,0.517266281512605) 52%, rgba(255,255,255,1) 100%); */
	background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(65,247,203,0.6881346288515406) 52%, rgba(255,255,255,1) 100%);
}

.footer-social-media-img {
	height: 30px;
	width: auto;
	margin-right: 5px;
	margin-bottom: 10px;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}


@media only screen and (max-width: 600px) {
	.custom {
		animation: scroll 20s linear infinite ;
		scroll-behavior: smooth;
	}
	
	.custom1 {
		animation: scroll 12s linear infinite ;
		scroll-behavior: smooth;
	}
	
	.custom2 {
		animation: scroll 16s linear infinite ;
		scroll-behavior: smooth;
	}
	
  }