/* .allotment_bg {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(65, 247, 203, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
} */

.allotment-letter-image {
  height: 350px;
  width: auto;
  border-radius: 20px;
}

.letter_parent_div {
  background-color: #41f7cb;
  border-radius: 30px;
  border: 1px solid black;
}

.letter_parent_child {
  padding: 5px 10px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 7px;
  border-radius: 30px;
  border: 1px solid black;
}

.allotment-third-parent-div {
  background-color: theme('colors.green.300');
  border: 1px solid black;
  border-radius: 30px;
}

.allotment-third-child-div {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 30px;
  text-align: center;
}

.inputField {
  border: 1px solid black;
  border-radius: 10px;
  padding: 8px 10px;
  display: block;
  width: 50%;
  margin-bottom: 15px;
}

@media only screen and (max-width: 600px) {

  .inputField {
    border: 1px solid black;
    border-radius: 10px;
    padding: 8px 10px;
    width: 80%;
    margin-bottom: 15px;
  }
}