.help-custom-card-bg {
  /* background-image: url("../../assets/HelpCardBg.png");
  background-repeat: no-repeat; */
  margin-top: 50px;
}

.customGreenBg {
  background-color: #41f7cb;
}

.student-rounded-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 1px solid #000;
}

.student-benefit-custom-bg {
  /* background-image: linear-gradient("to right", #41f7cb); */
  background: linear-gradient(156deg,
      theme('colors.white') 50%,
      /* rgba(65, 247, 203, 1) 100% */
      theme('colors.green.200'));
  padding-bottom: 5%;
}

.support-package-scroll-animation {
  animation: scroll 25s linear infinite;
  scroll-behavior: smooth;
  min-height: 300px;
}

.support-package-scroll-animation:hover {
  animation-play-state: paused;
  scroll-behavior: unset;
}

.customSpan {
  margin-top: 30px;
  bottom: 0;
  left: 50%;
  margin-bottom: -15px;
}

.student-benefit-custom-border {
  border-radius: 20px;
  border-right: 5px solid rgb(0, 0, 0, 0.7);
  border-bottom: 5px solid rgb(0, 0, 0, 0.7);
}

.support-package-card {
  border-radius: 20px;
  /* border-right: 1px solid rgb(0, 0, 0, 0.7); */
  border: 1px solid #000;
}

.ai-platform-section {
  /* background: linear-gradient(
    209deg,
    rgba(255, 255, 255, 0.25115983893557425) 60%,
    rgba(65, 247, 203, 1) 100%
  ); */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.people-saying-border {
  /* border-radius: 10px;
  border-right: 1px solid theme('colors.green.200');
  border-top: 1px solid theme('colors.green.200'); */
}

.people-saying-section {
  /* background: linear-gradient(
    179deg,
    rgba(255, 255, 255, 0.25115983893557425) 60%,
    rgba(65, 247, 203, 0.8) 100%
  ); */
}

.chat_screen_shot {
  height: 500px;
  width: auto !important;
  max-width: 300px;
}

.contact_bg {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, theme('colors.green.300') 35%, rgba(255, 255, 255, 1) 100%);
  height: 50vh;
}

/* .help_card_img {
  max-width: auto;
  height: 80px;
} */

@media only screen and (max-width: 600px) {
  .contact_bg {
    height: 35vh;
  }

  .help_card_img {
    max-width: auto;
    height: 80px;
  }
}