/* .contact_bg{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, theme('colors.green.300') 35%, rgba(255,255,255,1) 100%);
    height: 50vh;
} */

.custom-separator {
    border-top: 2px solid black;
    width: 25%;
    text-align: center;
    margin-top: 20px;
}

@media only screen and (max-width: 600px) {

    .allotment-separator {
        width: 40%;
    }
}