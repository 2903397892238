.about_us_card_parent{
    background-color: theme('colors.green.300');
    border: 1px solid black;
    border-radius: 40px;
}

.about_us_card_child{
    border: 1px solid black;
    border-radius: 40px;
    width: 99%;
    background-color: white;
    text-align: center;
}