

.allotment-separator {
    border-top: 3px solid black;
    width: 13%;
    text-align: center;
    margin-top: 20px;
}

.price_value{
    flex-direction: row;
}

@media only screen and (max-width: 600px) {
    .allotment-separator {
        width: 40%;
    }

    .price_value{
        flex-direction: column;
    }
    
}